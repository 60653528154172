import React, { useState, useEffect } from "react";
import "../App.scss";
import { GoDash } from "react-icons/go";
import { FaLongArrowAltLeft } from "react-icons/fa";

export function Landing() {
  const [mobile, setMobile] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [step, setStep] = useState(1);
  const [animationClass, setAnimationClass] = useState("fade-in");

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 1200);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tabs = [
    {
      url: "Tab1",
      title: "Dành Cho Người Lớn",
      description:
        "Tổng hợp link providers từ các trang hot như nangcuc, vlxx,...",
    },
    {
      url: "Tab2",
      title: "Xem Phim",
      description:
        "Tổng hợp link providers từ các trang như rophim, phimmoi,...",
    },
    {
      url: "Tab3",
      title: "Xem Thể Thao",
      description:
        "Tổng hợp link providers từ các trang hot như xoilac, cakeo,... ",
    },
  ];

  const providers = {
    1: {
      title: "Nơi tổng hợp link xem phim 18+ ",
      description:
        "Providers phim 18+ mang đến cho bạn trải nghiệm xem phim người lớn chất lượng cao với những đường link cập nhật liên tục từ các trang phim trực tuyến hàng đầu, đảm bảo tốc độ nhanh chóng và nội dung phong phú, đa dạng.",
      subhead: "Providers Phim 18+",
      api: "#",
    },
    2: {
      title: "Nơi tổng hợp link xem phim điện ảnh ",
      description:
        "Providers phim điện ảnh cung cấp những đường link xem phim mới nhất từ nhiều trang phim trực tuyến, mang đến cho bạn trải nghiệm xem phim nhanh chóng và tiện lợi.",
      subhead: "Providers Phim Điện Ảnh",
      api: "#",
    },
    3: {
      title: "Nơi tổng hợp link xem thể thao ",
      description:
        "Providers thể thao cung cấp đường link xem trực tiếp các sự kiện thể thao như bóng đá, bóng chuyền, bóng rổ và nhiều môn thể thao khác, giúp bạn dễ dàng theo dõi những trận đấu yêu thích một cách nhanh chóng.",
      subhead: "Providers Thể thao",
      api: "https://api.cakeo.xyz/providers",
    },
  };

  const handleTabChange = (index) => {
    setAnimationClass("fade-in");
    setTabSelected(index);
    setStep(2);
  };

  const HomeTab = () => (
    <div className={`Landing_Body_Tablist ${animationClass}`}>
      <div className="Landing_Body_Tablist_ItemStatic">
        <img
          src={`${process.env.PUBLIC_URL}/images/Tabstatic.png`}
          alt="Tabstatic"
          width={mobile ? "100%" : "310"}
          height={mobile ? "auto" : "434"}
        />
        <div className="Landing_Body_Tablist_ItemStatic_Popcorn">
          <img
            src={`${process.env.PUBLIC_URL}/images/Popcorn.png`}
            alt="Popcorn"
          />
        </div>
      </div>
      {tabs.map((tab, index) => (
        <div
          className="Landing_Body_Tablist_Item"
          key={index}
          onClick={() => handleTabChange(index + 1)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/${tab.url}.png`}
            alt={tab.url}
            width={310}
            height={434}
          />
          <div className="Landing_Body_Tablist_Item_Text">
            <div className="Landing_Body_Tablist_Item_Text_Left">
              <div className="Landing_Body_Tablist_Item_Text_Left_Title">
                {tab.title}
              </div>
              <div className="Landing_Body_Tablist_Item_Text_Left_Description">
                {tab.description}
              </div>
            </div>
            <div className="Landing_Body_Tablist_Item_Text_Right">
              <img
                src={`${process.env.PUBLIC_URL}/images/Icon.png`}
                alt="Arrow"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const SelectTab = () => (
    <div className="Landing_Body_TablistSelected">
      <div className={`Landing_Body_TablistSelected_ItemPage2 fade-in`}>
        <div className="Landing_Body_TablistSelected_ItemPage2_Left">
          <div className="Landing_Body_TablistSelected_ItemPage2_Left_Title">
            <img
              src={`${process.env.PUBLIC_URL}/images/Monplayer.png`}
              alt={tabSelected}
            />
            Monplayer
          </div>
          <div className="Landing_Body_TablistSelected_ItemPage2_Left_Image">
            <img
              src={`${process.env.PUBLIC_URL}/images/Appstore.png`}
              alt="Appstore"
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/Googleplay.png`}
              alt="Googleplay"
            />
          </div>
          <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content">
            <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_Head">
              {providers[tabSelected].title}
            </div>
            <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_Description">
              {providers[tabSelected].description}
            </div>
            <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo">
              <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Left">
                <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Left_Head">
                  {providers[tabSelected].subhead}
                </div>
                <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Left_Content">
                  {providers[tabSelected].api}
                </div>
              </div>
              <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Right">
                <img
                  src={`${process.env.PUBLIC_URL}/images/play-cricle.png`}
                  alt="Copy"
                />
              </div>
            </div>
            <div className="Landing_Body_TablistSelected_ItemPage2_Left_Content_Button">
              Xem ngay
            </div>
          </div>
        </div>
        <div
          className={`Landing_Body_TablistSelected_Item slide-in-from-left d-none d-xl-block`}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/Tab${tabSelected}.png`}
            alt={tabSelected}
          />
          <div className="Landing_Body_TablistSelected_Item_Text">
            <div className="Landing_Body_TablistSelected_Item_Text_Left">
              <div className="Landing_Body_TablistSelected_Item_Text_Left_Title">
                {tabs[tabSelected - 1].title}
              </div>
              <div className="Landing_Body_TablistSelected_Item_Text_Left_Description">
                {tabs[tabSelected - 1].description}
              </div>
            </div>
            <div className="Landing_Body_TablistSelected_Item_Text_Right">
              <img
                src={`${process.env.PUBLIC_URL}/images/Icon.png`}
                alt="Arrow"
              />
            </div>
          </div>
        </div>
        <div className="Landing_Body_TablistSelected_ItemPage2_Right d-none d-xl-block">
          <img src={`${process.env.PUBLIC_URL}/images/Video.png`} width={510} />
          <div className="Landing_Body_TablistSelected_ItemPage2_Right_Icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
            >
              <circle cx="27" cy="27.0004" r="21.6" fill="white" />
              <path
                d="M26.9326 4.5C14.5126 4.5 4.43256 14.58 4.43256 27C4.43256 39.42 14.5126 49.5 26.9326 49.5C39.3526 49.5 49.4326 39.42 49.4326 27C49.4326 14.58 39.3751 4.5 26.9326 4.5ZM33.6826 32.0175L27.1576 35.775C26.3476 36.2475 25.4476 36.4725 24.5701 36.4725C23.6701 36.4725 22.7926 36.2475 21.9826 35.775C20.3626 34.83 19.3951 33.165 19.3951 31.275V23.7375C19.3951 21.87 20.3626 20.1825 21.9826 19.2375C23.6026 18.2925 25.5376 18.2925 27.1801 19.2375L33.7051 22.995C35.3251 23.94 36.2926 25.605 36.2926 27.495C36.2926 29.385 35.3251 31.0725 33.6826 32.0175Z"
                fill="#6B4AE4"
              />
            </svg>
          </div>
        </div>
        <div className="Landing_Body_TablistSelected_Group_Mobile d-xl-none">
          <div className={`Landing_Body_TablistSelected_Item`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/Tab${tabSelected}.png`}
              alt={tabSelected}
            />
            <div className="Landing_Body_TablistSelected_Item_Text">
              <div className="Landing_Body_TablistSelected_Item_Text_Left">
                <div className="Landing_Body_TablistSelected_Item_Text_Left_Title">
                  {tabs[tabSelected - 1].title}
                </div>
                <div className="Landing_Body_TablistSelected_Item_Text_Left_Description">
                  {tabs[tabSelected - 1].description}
                </div>
              </div>
              <div className="Landing_Body_TablistSelected_Item_Text_Right">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Icon.png`}
                  alt="Arrow"
                />
              </div>
            </div>
          </div>
          <div className="Landing_Body_TablistSelected_ItemPage2_Right">
            <img
              src={`${process.env.PUBLIC_URL}/images/Video.png`}
              width={510}
            />
            <div className="Landing_Body_TablistSelected_ItemPage2_Right_Icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
              >
                <circle cx="27" cy="27.0004" r="21.6" fill="white" />
                <path
                  d="M26.9326 4.5C14.5126 4.5 4.43256 14.58 4.43256 27C4.43256 39.42 14.5126 49.5 26.9326 49.5C39.3526 49.5 49.4326 39.42 49.4326 27C49.4326 14.58 39.3751 4.5 26.9326 4.5ZM33.6826 32.0175L27.1576 35.775C26.3476 36.2475 25.4476 36.4725 24.5701 36.4725C23.6701 36.4725 22.7926 36.2475 21.9826 35.775C20.3626 34.83 19.3951 33.165 19.3951 31.275V23.7375C19.3951 21.87 20.3626 20.1825 21.9826 19.2375C23.6026 18.2925 25.5376 18.2925 27.1801 19.2375L33.7051 22.995C35.3251 23.94 36.2926 25.605 36.2926 27.495C36.2926 29.385 35.3251 31.0725 33.6826 32.0175Z"
                  fill="#6B4AE4"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Landing">
      <div className="Landing_Header">
        {tabSelected === 0 ? (
          <div className="Landing_Header_Text">
            <div style={{ whiteSpace: "nowrap", color: "#6B4AE4" }}>
              Bước 1: &nbsp;
            </div>
            <div>Hôm nay bạn muốn xem gì?</div>
          </div>
        ) : (
          <div
            className="Landing_Header_Text"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabSelected(0);
              setStep(1);
            }}
          >
            <FaLongArrowAltLeft />
            &nbsp;
            <div style={{ whiteSpace: "nowrap", color: "#6B4AE4" }}>
              Bước 2: &nbsp;
            </div>
            <div>Hướng dẫn sử dụng</div>
          </div>
        )}
      </div>
      <div
        className="Landing_Page"
        style={{
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          position: mobile ? "static" : "absolute",
          right: "0px",
          top: "108px",
          marginBottom: "20px",
        }}
      >
        {[1, 2].map((num) => (
          <div
            key={num}
            className="Landing_Page_Tab"
            style={{ backgroundColor: step === num ? "#6B4AE4" : "" }}
          >
            <GoDash color={step === num ? "#fff" : ""} />
          </div>
        ))}
      </div>
      <div className="Landing_Body">
        {tabSelected === 0 ? <HomeTab /> : <SelectTab />}
        <div className="Landing_Body_Information">
          <div className="Landing_Body_Information_Title">
            NƠI CUNG CẤP LINK &nbsp;
            <span style={{ color: "#6B4AE4" }}>PROVIDER</span>
            &nbsp; CHO BẠN
          </div>
          <div className="Landing_Body_Information_Content">
            “ Link Providers là một đường dẫn hoặc URL được sử dụng để kết nối
            và truy cập các nguồn phát nội dung trực tuyến. ”
          </div>
          <div className="Landing_Body_Information_Guarantee">
            {[
              "Cung cấp link an toàn.",
              "Xem miễn phí.",
              "Thao tác copy và điền link dễ dàng.",
              "Cá nhân hoá không gian xem.",
              "Các nền tảng nổi tiếng",
            ].map((text, index) => (
              <div
                className="Landing_Body_Information_Guarantee_Box"
                key={index}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/tick-circle.png`}
                  alt="Guarantee"
                />
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
