import "./App.scss";
import { Landing } from "./Landing/landing";

function App() {
  return (
    <div className="App">
      <Landing /> 
    </div>
  );
}

export default App;
